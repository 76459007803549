
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import chapitresApi from "@/core/services/ChapitresApi";
import { ElMessage, ElMessageBox } from "element-plus";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "Chapitres",
    components: { Filters, FilterTags },
    data() {
        return {
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: false,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: false,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
                pouvoirs: true,
            },
            loading: true,
            chapitreSearch: "",
            incoming_chapters: [] as any,
            old_chapters: [] as any,
            currentIncomingPage: 1,
            currentOldPage: 1,
            totalIncomingPages: 0,
            totalOldPages: 0,
            selectedChapters: [] as any,
            actualFilter: "",
            actualCol: "",
            incoming_chapters_th: [
                { label: "Date", class: "min-w-60px", colName: "date" },
                { label: "Location", class: "min-w-60px", colName: "location" },
                {
                    label: "Inducting officer",
                    class: "min-w-60px",
                    colName: "master",
                },
                {
                    label: "Pouvoirs received",
                    class: "min-w-300px",
                    colName: "pouvoir",
                },
                {
                    label: "Participants",
                    class: "min-w-60px",
                    colName: "participants",
                },
            ],
            old_chapters_th: [
                { label: "Date", class: "min-w-60px", colName: "date" },
                { label: "Location", class: "min-w-60px", colName: "location" },
                {
                    label: "Inducting officer",
                    class: "min-w-200px",
                    colName: "master",
                },
                {
                    label: "Livre d'or received",
                    class: "min-w-50px",
                    colName: "livre_dor",
                },
                {
                    label: "Livre d'or uploaded",
                    class: "min-w-50px",
                    colName: "livre_dor_uploaded",
                },
                { label: "Pouvoirs", class: "min-w-50px", colName: "pouvoir" },
                {
                    label: "Participants validated",
                    class: "min-w-225px",
                    colName: "participants",
                },
            ],
            perm_pos: "",
            intoOld: false,
            allChecked: false,
            allChaptersSelected: false,
            selectionScope: "add",
            selectionPayload: {
                type: "add",
                ids: [] as any,
            },
        };
    },
    setup() {
        // Number of items per page
        const resultsPerIncomingPage = 10;
        const resultsPerOldPage = 10;
        const router = useRouter();

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Chapitres", []);
        });

        return {
            resultsPerIncomingPage,
            resultsPerOldPage,
            router,
            debounce: createDebounce(),
        };
    },
    mounted() {
        this.getIncomingChapters();
        this.getOldChapters();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSelection(scope: string, event: any, chapitre?: any) {
            if (scope === "top") {
                event.target.checked
                    ? (this.allChecked = true)
                    : (this.allChecked = false);
            } else if (scope === "single") {
                this.allChecked
                    ? (this.selectionScope = "remove")
                    : (this.selectionScope = "add");
                if (event.target.checked) {
                    this.allChecked
                        ? this.selectedChapters.splice(
                              this.selectedChapters.indexOf(chapitre.id),
                              1
                          )
                        : this.selectedChapters.push(chapitre.id);
                } else {
                    this.allChecked
                        ? this.selectedChapters.push(chapitre.id)
                        : this.selectedChapters.splice(
                              this.selectedChapters.indexOf(chapitre.id),
                              1
                          );
                }
            }

            this.selectionPayload = {
                type: this.selectionScope,
                ids: this.selectedChapters,
            };
        },
        exportChapters() {
            var payload = {
                type: this.intoOld ? "old" : "incoming",
                selection: this.selectionPayload,
            };

            if (
                this.selectionPayload.ids.length === 0 &&
                this.selectionPayload.type === "add"
            ) {
                ElMessage({
                    type: "error",
                    message: "Please select at least one chapitre.",
                });
                return;
            }
            chapitresApi.exportList(payload).then((res: any) => {
                if (res.success) {
                    window.open(res.data.list_chapitre_url, "_blank");
                } else {
                    ElMessage({
                        type: "error",
                        message:
                            "An error occurred while downloading chapters.",
                    });
                }
            });
        },
        handleSizeChange(scope: string) {
            scope === "incoming"
                ? this.getIncomingChapters()
                : this.getOldChapters();
        },
        deleteChapitres(chapitreId?: number) {
            var chapitresIdToDelete = [] as any;

            // If single delete
            if (chapitreId) {
                chapitresIdToDelete.push(chapitreId);
                ElMessageBox.confirm(
                    "You have requested the deletion of a chapitre.<br>Would you like to confirm this choice?",
                    "Confirmation",
                    {
                        confirmButtonText: "Confirm",
                        cancelButtonText: "Cancel",
                        customClass: "custom-modal",
                        cancelButtonClass: "cancel-modal",
                        dangerouslyUseHTMLString: true,
                    }
                )
                    .then(() => {
                        this.loading = true;
                        chapitresApi
                            .deleteChapitres(chapitresIdToDelete)
                            .then(() => {
                                this.selectedChapters = [];
                                this.currentIncomingPage = 1;
                                this.currentOldPage = 1;
                                this.loading = false;
                                ElMessage({
                                    type: "success",
                                    message: "Chapitre deleted.",
                                });
                                this.getIncomingChapters();
                                this.getOldChapters();
                            });
                    })
                    .catch(() => {
                        return; // Cancel
                    });

                // Else if multiple delete
            } else {
                chapitresIdToDelete = this.selectedChapters;
                ElMessageBox.confirm(
                    "You have requested the deletion of multiple chapitres.<br>Would you like to confirm this choice?",
                    "Confirmation",
                    {
                        confirmButtonText: "Confirm",
                        cancelButtonText: "Cancel",
                        customClass: "custom-modal",
                        cancelButtonClass: "cancel-modal",
                        dangerouslyUseHTMLString: true,
                    }
                )
                    .then(() => {
                        this.loading = true;
                        chapitresApi
                            .deleteChapitres(chapitresIdToDelete)
                            .then(() => {
                                this.selectedChapters = [];
                                // If we delete all the entries on the last page, we need to go back to the previous page
                                if (
                                    this.currentIncomingPage ===
                                        this.totalIncomingPages &&
                                    this.selectedChapters.length ===
                                        this.incoming_chapters.length
                                ) {
                                    this.currentIncomingPage =
                                        this.currentIncomingPage - 1;
                                }
                                if (
                                    this.currentOldPage ===
                                        this.totalOldPages &&
                                    this.selectedChapters.length ===
                                        this.old_chapters.length
                                ) {
                                    this.currentOldPage =
                                        this.currentOldPage - 1;
                                }

                                this.currentIncomingPage = 1;
                                this.currentOldPage = 1;
                                this.loading = false;
                                ElMessage({
                                    type: "success",
                                    message: "Chapitres deleted.",
                                });
                                this.getIncomingChapters();
                                this.getOldChapters();
                            });
                    })
                    .catch(() => {
                        return; // Cancel
                    });
            }
        },
        addChapitre() {
            this.router.push({ name: "chapitre-add", params: { mode: "add" } });
        },
        manageChapitres(action: string, id?: number) {
            var chapitresId = [] as any;
            var is_locked;

            if (id) chapitresId.push(id);
            else chapitresId = this.selectedChapters;

            if (action === "lock") is_locked = true;
            else if (action === "unlock") is_locked = false;

            chapitresApi
                .manageChapitres({ chapitres: chapitresId, is_locked })
                .then(() => {
                    this.selectedChapters = [];
                    this.getIncomingChapters();
                    this.getOldChapters();
                    ElMessage({
                        type: "success",
                        message: "Chapitre " + action + "ed.",
                    });
                });
        },
        applyFilters() {
            var closeFilters = document.getElementById("filter") as any;
            closeFilters.click();
            this.getIncomingChapters();
            this.getOldChapters();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        editChapitre(id: number, newTab?: boolean) {
            if (newTab) {
                const routeData = this.router.resolve({
                    name: "chapitre-details",
                    params: { chapitre_id: id, mode: "edit" },
                });
                window.open(routeData.href, "_blank");
            } else {
                this.router.push({
                    name: "chapitre-details",
                    params: { chapitre_id: id, mode: "edit" },
                });
            }
        },
        getIncomingChapters() {
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentIncomingPage,
                        presult: this.resultsPerIncomingPage,
                        filters: this.filtersPayload,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentIncomingPage,
                        presult: this.resultsPerIncomingPage,
                        filters: this.filtersPayload,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentIncomingPage,
                    presult: this.resultsPerIncomingPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentIncomingPage,
                    presult: this.resultsPerIncomingPage,
                };
            }

            if (this.chapitreSearch) {
                payload = { ...payload, query: this.chapitreSearch };
            }

            payload.type = "incoming";

            chapitresApi.getChapitres(payload).then((res: any) => {
                this.loading = false;
                this.incoming_chapters = res.data.chapitre_list;
                this.totalIncomingPages = res.data.page_count;
            });
        },
        getOldChapters() {
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentOldPage,
                        presult: this.resultsPerOldPage,
                        filters: this.filtersPayload,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentOldPage,
                        presult: this.resultsPerOldPage,
                        filters: this.filtersPayload,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentOldPage,
                    presult: this.resultsPerOldPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentOldPage,
                    presult: this.resultsPerOldPage,
                };
            }

            if (this.chapitreSearch) {
                payload = { ...payload, query: this.chapitreSearch };
            }

            payload.type = "old";

            chapitresApi.getChapitres(payload).then((res: any) => {
                this.loading = false;
                this.old_chapters = res.data.chapitre_list;
                this.totalOldPages = res.data.page_count;
            });
        },
        sortColumn(column: string, id: number, scope: string) {
            var arrows = document.getElementById(
                "chevrons-" + scope + "-" + id
            );

            // Hide all arrows
            Array.from(
                document.getElementsByClassName(
                    "chevrons-container-" + scope
                ) as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up", scope);
                this.handleChevron("hide", "down", scope);

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up", scope);
                    this.handleChevron("show", "down", scope);
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up", scope);
                    this.handleChevron("hide", "down", scope);
                    this.actualFilter = "asc";
                }
            }
            if (scope === "incoming") this.getIncomingChapters();
            else this.getOldChapters();
        },
        handleChevron(action: string, direction: string, scope: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName(
                        "chapter-u-chevron-" + scope
                    ) as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName(
                        "chapter-d-chevron-" + scope
                    ) as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any, scope: string) {
            this.selectedChapters = [];
            var topCheckbox = document.getElementById(
                "topCheckbox-" + scope
            ) as any;
            topCheckbox.checked = false;

            if (scope === "incoming") {
                this.currentIncomingPage = pageNumber;
                this.getIncomingChapters();
            } else {
                this.currentOldPage = pageNumber;
                this.getOldChapters();
            }
        },
        checkAll(scope: string) {
            var topCheckbox = document.getElementById(
                "topCheckbox-" + scope
            ) as any;
            this.allChaptersSelected = !this.allChaptersSelected;
            this.selectedChapters = [];
            topCheckbox.checked
                ? (this.selectionScope = "remove")
                : (this.selectionScope = "add");
        },
        handleActionSelect(chapitre: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + chapitre.id
            ) as any;

            // if (focusedCheckbox?.checked) {
            //     this.selectedChapters.push(chapitre.id);
            // } else {
            //     this.selectedChapters.map((chapitre: any) => {
            //         if (chapitre.id === chapitre.id) {
            //             this.selectedChapters.splice(this.selectedChapters.indexOf(chapitre), 1);
            //         }
            //     });
            // }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];
            this.getIncomingChapters();
            this.getOldChapters();
        },
        queryChanged(query: string) {
            this.chapitreSearch = query;
            this.getIncomingChapters();
            this.getOldChapters();
        },
    },
});
